<template>
    <div>
      <h4 style="text-align: center">Nova Engel</h4>
      <hr />
      <div style="text-align: center">
        <b-button class="shipping" @click="openModalNE">➕</b-button>
      </div>
      <hr />
      <div style="align-items: center">
        <table
          style="
            text-align: center;
            border-spacing: 10px;
            border-collapse: separate;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <tr>
            <th>Marca</th>
            <th>Divisão</th>
            <th>Stock Min.</th>
            <th>Max Divisão</th>
            <th></th>
          </tr>
          <tr v-for="stock in novaEngelStockConditions" :key="stock.id">
            <td>{{ stock.brand }}</td>
            <td>
              <input id="length" type="number" class="form-control" v-model="stock.division" />
            </td>
            <td>
              <input id="length" type="number" class="form-control" v-model="stock.stock_min" />
            </td>
            <td>
              <input id="length" type="number" class="form-control" v-model="stock.max_division" />
            </td>
            <td>
              <a id="updateValues" @click="updateNovaEngelCond(stock)">🔄</a>
              <a id="updateValues" @click="deleteNovaEngelCond(stock)">❌</a>
            </td>
          </tr>
        </table>
      </div>
      <hr />
      <b-modal ref="modal-NE" id="modal-2" title="Adicionar Nova Engel Condição" hide-footer>
      <form v-on:submit.prevent="addNovaEngelCondition()">
        <div class="form-group">
          <label for="brandSelect">Marca:</label>
          <v-select :options="brands" v-model="new_brand_id" label="name" id="brandSelect">
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
        </div>
        <div class="form-group">
          <label for="divisionInput">Divisão:</label>
          <input type="number" class="form-control" id="divisionInput" v-model="new_division" />
        </div>
        <div class="form-group">
          <label for="stockMinInput">Mínimo Stock:</label>
          <input type="number" class="form-control" id="stockMinInput" v-model="new_stock_min" />
        </div>
        <div class="form-group">
          <label for="maxDivisionInput">Max Divisão:</label>
          <input type="number" class="form-control" id="maxDivisionInput" v-model="new_max_division" />
        </div>
        <div id="btnUpdateAddress">
          <button type="submit" class="btn btn-primary">Adicionar</button>
        </div>
      </form>
    </b-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default{
    data: function(){
        return{
            novaEngelStockConditions: [],
            brands: [],
            new_brand_id: '',
            new_division: '',
            new_stock_min: '',
            new_max_division: '',
        }
    },
    methods: {
        async loadData(){
            await this.getNovaEngelStockConditions();
            await this.getBrandsF();
        },
        async addNovaEngelCondition() {
            console.log(this.new_brand_id.id, this.new_division, this.new_stock_min, this.new_max_division);
            try {
                await this.$store.dispatch('createNovaEngelStockCond', {
                brand_id: this.new_brand_id.id,
                division: this.new_division,
                stock_min: this.new_stock_min,
                max_division: this.new_max_division,
                });
                this.$swal({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: `Condição Adicionada`,
                showConfirmButton: false,
                timer: 1500,
                });
                await this.getNovaEngelStockConditions();
                this.$refs['modal-NE'].hide();
            } catch (error) {
                alert(error);
            }
        },
        async getBrandsF() {
            try {
                await this.$store.dispatch('getAllBrands');
                this.brands = this.getBrands;
                console.log('Marcas', this.getBrands);
            } catch (error) {
                console.log(error);
            }
        },
        async openModalNE() {
            this.$refs['modal-NE'].show();
        },
        async getNovaEngelStockConditions() {
            try {
                await this.$store.dispatch('getNovaEngelStockCond');
                this.novaEngelStockConditions = this.getNovaEngelStock;
            } catch (error) {
                console.log(error);
            }
        },
        async updateNovaEngelCond(stock) {
            console.log(stock);
            this.$swal({
                title: 'Quer atualizar ' + stock.brand + '?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, atualizar!',
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                try {
                    await this.$store.dispatch('updateNovaEngelStockCond', {
                    id: stock.id,
                    division: stock.division,
                    stock_min: stock.stock_min,
                    max_division: stock.max_division,
                    });
                    this.$swal({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: `Condição atualizada`,
                    showConfirmButton: false,
                    timer: 1500,
                    });
                    await this.getNovaEngelStockConditions();
                } catch (error) {
                    alert(error);
                }
                }
            });
        },
            async deleteNovaEngelCond(stock) {
            console.log(stock);
            this.$swal({
                title: 'Quer eliminar ' + stock.brand + '?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, eliminar!',
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                try {
                    await this.$store.dispatch('deleteNovaEngelStockCond', {
                    id: stock.id,
                    });
                    this.$swal({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: `Condição eliminada`,
                    showConfirmButton: false,
                    timer: 1500,
                    });
                    await this.getNovaEngelStockConditions();
                } catch (error) {
                    alert(error);
                }
                }
            });
        },
    },
    created(){
        this.loadData();
    },
    computed: {
        ...mapGetters(['getNovaEngelStock', 'getBrands'])
    }
}
</script>

<style>
.container {
  text-align: center;
}
.validationAdress {
  text-align: center;
  margin: 0px 600px 0px 600px;
}
h2 {
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
}
table {
  margin: 1em 0;
  border-collapse: collapse;
  border: 0.1em solid #d6d6d6;
  text-align: center;
}
th,
td {
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: center;
  text-indent: -0.5em;
}

th {
  vertical-align: bottom;
  background-color: #666;
  color: #fff;
}

tr:nth-child(even) th[scope='row'] {
  background-color: #f2f2f2;
}

tr:nth-child(odd) th[scope='row'] {
  background-color: #fff;
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

th:nth-of-type(3),
td:nth-of-type(3) {
  text-align: center;
}

#updateValues {
  margin-left: 5px;
  cursor: pointer;
}
#updateValues:hover {
  cursor: pointer;
}

.shipping {
  margin: 0px 5px 0px 5px;
  background-color: #8fb1cd;
  border-color: #8fb1c1;
}
.shipping:hover {
  background-color: #7097b4;
  border-color: #7097b4;
}

.autoPrice {
  margin: 0px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.autoPrice:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}
.table {
  text-align: center;
}
</style>